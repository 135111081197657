import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#2a2e33] text-white py-8">
      <div className="container mx-auto text-center">

        <div className="flex justify-center items-center">
          {/* WCAG 2.1 Logo */}
          <a href="https://www.w3.org/WAI/WCAG2AAA-Conformance" title="Explanation of WCAG 2 Level AAA conformance">
            <img 
              height="32"
              width="88"
              src="https://www.w3.org/WAI/WCAG21/wcag2.1AAA-blue-v"
              alt="Level AAA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
            />
          </a>

          {/* Copyright Text */}
          <p className="text-xs ml-2">&copy; {new Date().getFullYear()} Drake Hooks.</p>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
