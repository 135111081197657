import React from 'react';
import CaptchaSolver from '../assets/captcha_solver.jpg';
import WebCrawler from '../assets/webcrawler3.jpg';
import Tshirt from '../assets/tshirtform.jpg';

const Work = () => {
  return (
    <div name='work' className='w-full mt-40 md:mt-20 lg:mt-0 xl:mt-0 md:h-screen text-gray-300 bg-[#282828]'>

      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <h1 className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#3498DB]' role="heading" aria-level="1">Projects</h1>
          <p className='py-6'>Projects I have worked on.</p>
        </div>

        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
          <div style={{backgroundImage: `url(${CaptchaSolver})` }} 
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
            
            <div className="opacity-0 group-hover:opacity-100">
              <h2 className='text-2xl font-bold text-white tracking-wider'>Captcha Solver (2023)</h2>
              <div className='pt-8 text-center'>
                <a href="https://github.com/DrakeHooks/CaptchaSolver" target="_blank" rel="noopener noreferrer" class="hover:text-white" style={{ transition: 'color 0.9s', backgroundColor: 'white', padding: '10px 20px', borderRadius: '4px', textDecoration: 'none' }}>
                  <span className="sr-only">Opens in a new tab</span>
                  <span style={{ color: 'black', fontSize: '18px', textDecoration: 'none' }}>View</span>
                </a>
              </div>
            </div>
          </div>
          
          <div style={{backgroundImage: `url(${WebCrawler})` }} 
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
            
            <div className="opacity-0 group-hover:opacity-100">
              <h2 className='text-2xl font-bold text-white tracking-wider'>Web Scraper (2021)</h2>
              <div className='pt-8 text-center'>
                <a href="https://github.com/DrakeHooks/webscraper" target="_blank" rel="noopener noreferrer" class="hover:text-white" style={{ transition: 'color 0.9s', backgroundColor: 'white', padding: '10px 20px', borderRadius: '4px', textDecoration: 'none' }}>
                  <span className="sr-only">Opens in a new tab</span>
                  <span style={{ color: 'black', fontSize: '18px', textDecoration: 'none' }}>View</span>
                </a>
              </div>
            </div>
          </div>

          <div style={{backgroundImage: `url(${Tshirt})` }} 
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">
            
            <div className="opacity-0 group-hover:opacity-100">
              <h2 className='text-2xl font-bold text-white tracking-wider'>T-Shirt Form (2023)</h2>
              <div className='pt-8 text-center'>
                <a href="https://github.com/DrakeHooks/tshirtorderform" target="_blank" rel="noopener noreferrer" class="hover:text-white" style={{ transition: 'color 0.9s', backgroundColor: 'white', padding: '10px 20px', borderRadius: '4px', textDecoration: 'none' }}>
                  <span className="sr-only">Opens in a new tab</span>
                  <span style={{ color: 'black', fontSize: '18px', textDecoration: 'none' }}>View</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
