import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link as ScrollLink } from 'react-scroll';
import BlobSVG from '../assets/blob3.svg';

const Header = () => {
  return (
    <header name="home" className='w-full h-screen bg-[#282828] flex'>
      {/* Header Content */}
      <div className='max-w-[1000px] flex flex-col justify-center text-white lg:ml-80 '>
        <p>Hey there, I'm</p>
        <h1 className='text-4xl sm:text-7xl mt-2 '>Drake Hooks</h1>
        <h2 className='mt-2'>I'm a developer focused on technology and solutions.</h2>

        {/* Move the ScrollLink component to wrap only the button */}
        <ScrollLink
          to="work"
          spy={true}
          smooth={true}
          duration={500}
          offset={-90}
        >
          <button className='text-white group border-2 px-5 py-2 my-2 mt-2 flex items-center hover:bg-[#3498DB] hover:border-[#3498DB]'>
            View Work
            <span className='group-hover:translate-x-3 duration-300'>
              <HiArrowNarrowRight className='ml-2 hover:' />
            </span>
          </button>
        </ScrollLink>
      </div>

      {/* Blob SVG */}
      <div className="hidden lg:block">
        <img src={BlobSVG} alt="Background blob" className="w-full h-full" />
      </div>
    </header>
  );
}

export default Header;
