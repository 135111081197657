import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-100vh md:h-100vh bg-[#282828]'>
        <div className='flex flex-col justify-center  w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className=' pb-8 pl-4'>
            


          </div>
          <div></div>
          </div>
          <div class=" p-8 md:p-12 bg-[#2a2e33] dark:text-white"><div class="flex"><div class="text-2xl md:text-3xl m-auto font-display md:leading-relaxed max-w-4xl space-y-8"><p> I'm a <span class="font-bold">📊 developer </span> learning popular frameworks through school, and online resources.

            </p>
            <p>I'm based in <span class="font-bold"> 🏖️ Wilmington, NC.</span> I'm currently a student at
                <span class="font-bold">🦈 Cape Fear Community College</span>.
            </p>
            <p>I like <span class="font-bold">😋 Food</span>,
                <span class="font-bold">👷‍♂️ Legos</span>,
                <span class="font-bold">🎵 Music</span>, <span class="font-bold">💻 Tech</span>, and
                <span class="font-bold">📚 Learning</span>.
            </p></div></div></div>

      </div>

    </div>
  )
}

export default About