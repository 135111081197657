import About from "./components/About";
import Header from "./components/Header";
import Navbar from "./components/navbar";
import Skills from "./components/skills";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

export default function App() {
  return ( 
    <div className='bg-[#282828]'>
      <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        


      </Routes>
</BrowserRouter>
    </div>
  )
}


