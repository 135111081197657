import About from "../components/About";
import Header from "../components/Header";
import Navbar from "../components/navbar";
import Skills from "../components/skills";
import Work from "../components/Work";
import Footer from "../components/Footer";
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {



  return (
    

      
    <div>
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
